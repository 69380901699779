<template>
  <div class="home">

    <div class="menu">

      <div class="navigation3" id="overall"  @click="mapok">
        <img src="./assets/image/quanju.png">
      </div>

      <div v-if="mute" class="navigation3" id="close" style="margin-left: 20px;" @click="clickMusicIcon">
        <img src="./assets/image/soundC.png">
      </div>

      <div v-else class="navigation3" id="voice" style="margin-left: 20px;" @click="MusicIconst">
        <img src="./assets/image/soundO.png">
      </div>
      
      <div style="margin-top: 6px;margin-left: 20px;">访问量: {{figure}}</div>

    </div>


    <div class="menu menu_i" v-if="show8">

      <div class="text">
        <div class="navigation3_1" @click="textok">
          <img src="./assets/image/close.png" />
        </div>
        <div>
          欢迎来到皓量科技元宇宙展厅！<br/>
          皓量科技提供一站式数字孪生可视化管理平台，拥有强大的研发团队。在元宇宙世界中创造虚拟空间与“复刻”现实的实体对象。实现可视化、智能化、沉浸化、的元宇宙虚拟空间，快速打造高品质可传播的虚拟交互空间。助力客户加速数字化变革，抢占数字经济发展高地。<br/>
          联系我们：15751835288  13770336001  
        </div>

      </div>
    </div>


    <div class="map" v-if="show9">
 
      <div class="map_i">
        <img src="./assets/image/ditu.png">
      </div>

      <div class="map_d" :style="[{bottom:x+'px'},{left:y+'px'}]">
        <img src="./assets/image/location.png">
      </div>

    </div>

    <div class="go" v-if="show10" style="height: 100%">
      <iframe :src="src" width="100%" height="100%" style="border: medium none;"></iframe>
    </div>

    <div class="go" v-if="show"  id="videoplay">

      <div id="Loading" ref="loading" v-if="load">
        <div class="turn">
        </div>
        <div class="loadText">{{ lodingPer }}%</div>
      </div>

      <div class="different"><img src="./assets/image/wuti.png" style="opacity: 0.5;"></div>
      <div class="go_div" v-if="show1">
        <div class="go_ont">
          <div class="go_ont_i"><img src="./assets/image/bg.png"></div>
          <div class="go_ont_t">
            <div class="go_ont_t_i"><img src="./assets/image/sound.png"></div>
            <div class="go_ont_tt">{{ title }}</div>

            <div class="go_ont_q_i">
              <div class="go_ont_q" @click="good">好的</div>
            </div>
          </div>
        </div>
      </div>

      <div class="go_div_o" v-if="show2">
        <div class="go_ont_q_i1"><img src="./assets/image/button2.png"></div>
        <div class="go_ont_q1" @click="start" >开始探索</div>
      </div>

    </div>

    <div class="navigation" v-if="show3">
      <div class="navigation1">
        <div class="navigation2" v-if="show4">
          <div class="navigation3">
            <img src="./assets/image/quanju.png">
          </div>
          <div class="navigation4">
            <img src="./assets/image/guide.png">
          </div>
          <div class="navigation5">全局展览</div>
        </div>

        <div class="navigation2" style="margin-left: 75px;" v-if="show5">
          <div class="navigation3">
            <img src="./assets/image/soundO.png">
          </div>
          <div class="navigation4">
            <img src="./assets/image/guide.png">
          </div>
          <div class="navigation5">打开或关闭游戏音效</div>
        </div>

        <div v-if="show6"></div>
        <div v-if="show7"></div>

        <div class="navigation5" @click="skip">
          <div>跳过引导</div>
        </div>
      </div>

      <div class="slide" v-if="show6">
        <div class="slide1"><span class="slide1_1">滑动摇杆</span>，方向自由移动</div>
        <div class="slide2"><img src="./assets/image/shoushi1.png"></div>
      </div>


      <div class="rotary" v-if="show7">
        <div class="slide1"><span class="slide1_1">旋转滑动</span>屏幕，可观看四周</div>
        <div class="slide2"><img src="./assets/image/huadong.png"></div>
      </div>

      <div class="nextstep">
        <div class="nextstep_o" @click="laststep">{{ show7 ? '进入展馆' : '下一步' }}</div>
      </div>
    </div>
    
    <video id="video" loop crossOrigin="anonymous" playsinline webkit-playsinline x5-video-player-type="h5-page" style="display:none">
			<source src="" type='video/mp4; '>
		</video>

    <Star size="50" top="50" left="400" delay="0" />
    <Star size="70" top="150" right="250" delay="0.3" />
    <Star size="90" top="250" left="250" delay="1.1" />
    <Star size="100" top="340" right="400" delay="1.7" />
    <Star size="80" top="400" left="350" delay="2" />
    <!-- 流星 -->
    <Meteor size="5" top="100" left="450" delay="0" />
    <Meteor size="5" top="150" left="800" delay="3" />
    <Meteor size="5" top="200" right="350" delay="7" />
    <div class="canvas-container" ref="canvasDom"></div>
  </div>
</template>
<script>

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { Octree } from "three/examples/jsm/math/Octree.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Capsule } from "three/examples/jsm/math/Capsule.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { Reflector } from "three/examples/jsm/objects/Reflector.js";
// import Stats from 'three/examples/jsm/libs/stats.module.js';
import nipplejs from "nipplejs";
import axios from "axios";
import TWEEN from "@tweenjs/tween.js";

import Star from "./components/star";
import Meteor from "./components/meteor";

let renderer, oControls;
// let stats;

//人物,相机,和轨道之间需要调试(统一的偏移量)(只有x管用)
let offsetVec = new THREE.Vector3(0.5, 20, 0);

//计时器
let clock = new THREE.Clock();
// console.log("axios---> ", axios);
//重力
let GRAVITY = 5;
const STEPS_PER_FRAME = 5;
//加载完成
let isLoadSceneFinish = false;
//图片列表 
//name: 'https://www.baidu.com/', img: 'http://vrgame.zhongyigames.com/storage/goodsImg/20230504/d52267559a383ec4ff8f7b681e77a5e9.jpg', booth: 1
let picList = [];
//摇杆
let isTouchRocker = false;
let tmpRockRadian = null;
let lastTmpRockRadian = Math.PI;
let startOControl = Math.PI / 2;

let peopleObj, mixer, stoneMixer, boxMixer,npcMixer,circleLightMixer;
let groundMirror;
var baseActions = {
  idle: { weight: 1 },
  walk: { weight: 0 },
  run: { weight: 0 },
};

let walkFlag = false;
//八叉树
let worldOctree = new Octree();

let playerOnFloor = false;
//远程资源
let isRemote = false;
// let remoteModlePath = "https://lianliankan.zhongyigames.com/image/web/";
let remoteModlePath = "https://osscdn.zhongyigames.com/zhanting/";
let keyStates = {};

let playerVelocity = new THREE.Vector3();
let playerDirection = new THREE.Vector3();
let rockDirection = new THREE.Vector3();
//轨道相机
let startCameraPos = new THREE.Vector3(-9.5, 24.4, -0.3);
let startControlPos = new THREE.Vector3(0.5, 0.85, -0.5);

let endCameraPos = new THREE.Vector3(6.57, 1.84, -18.34);
let endControlPos = new THREE.Vector3(5.89, 1.15, -16.49);

let isCameraAction = false;

let joystick;
let joysticks;
let mp3 = new Audio('../sounds/background.mp3') // 创建音频对象


export default {
  name: "app",
  components: {
    Star,
    Meteor,
  },
  data() {
    return {
      lodingPer: 0,
      mute: true,
      load: 1,
      show: 1,
      show1: 0,
      show2: 0,
      show3: 0,
      show4: 0,
      show5: 0,
      show6: 0,
      show7: 0,
      show8: 0,
      show9:0,
      show10:0,
      x:20,
      y:35,
      figure:0,
      src:'',
      token:'',
      title: '皓量科技欢迎你！一起来探索吧～~'
    };
  },
  mounted() {
    // this.createJoysticks();
    this.init3D();
    let that = this;

    let url = window.location.href;
    
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
    }

    let token = obj.token;
    this.token = token;
    console.log('token',token);

    window.addEventListener('message', function(event) {
        // // 通过origin对消息进行过滤，避免遭到XSS攻击
        if (event.origin === 'https://leixiang.zhongyigames.com') {
          that.show10 = 0;
        }

    });
  },
  methods: {
    mapok() {
      this.show9 = !this.show9;
    },
    textok() {
      this.show8 = 0;
    },
    MusicIconst() {
      this.mute = true;
      mp3.loop = false;
      mp3.pause();
      mp3.load();
    },
    clickMusicIcon() {
      this.mute = false;
      mp3.loop = true;
      mp3.play() ;
    },
    good() {
      this.show1 = 0;
      this.show2 = 1;
    },
    start() {
      this.show = 0;
      this.show2 = 0;
      this.show3 = 1;
      this.show4 = 1;
    },
    skip() {
      this.show3 = 0;
      isCameraAction = true;
    },
    laststep() {
      if (this.show4 == 1) {

        this.show4 = 0;
        this.show5 = 1;
      } else if (this.show5 == 1) {
        this.show5 = 0;
        this.show6 = 1;
      } else if (this.show6 == 1) {
        this.show6 = 0;
        this.show7 = 1;
      } else {
        this.show3 = 0;
        this.show7 = 0;
        isCameraAction = true;
      }

    },
    init3D() {
      let _this = this;
      let video = document.getElementById('video');
      let videoplay = document.getElementById("videoplay");
      let havePlayVideo = false;
      //这里可能会多次调用,只让播放一次
      videoplay.onclick = function () {
        if (!havePlayVideo) {
          // console.log('触发点击视频播放')
          havePlayVideo = true;
          initVideo();
          _this.clickMusicIcon();
        }
      }

      let canvasDom = this.$refs.canvasDom
      //场景
      let scene = new THREE.Scene();

      //摄像机
      let camera = new THREE.PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        0.1,
        10000
      );
      camera.rotation.order = "YXZ";
      //nzp
      // camera.position.set(0, 2, -1.8);
      // camera.position.add(offsetVec);
      camera.position.set(startCameraPos.x, startCameraPos.y, startCameraPos.z);

      //环境光
      let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(-5, 25, -1);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.near = 0.01;
      directionalLight.shadow.camera.far = 500;
      directionalLight.shadow.camera.right = 30;
      directionalLight.shadow.camera.left = -30;
      directionalLight.shadow.camera.top = 30;
      directionalLight.shadow.camera.bottom = -30;
      directionalLight.shadow.mapSize.width = 1024;
      directionalLight.shadow.mapSize.height = 1024;
      directionalLight.shadow.radius = 4;
      directionalLight.shadow.bias = -0.00006;
      scene.add(directionalLight);

      //渲染器
      renderer = new THREE.WebGLRenderer({
        antialias: true, //抗锯齿
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.VSMShadowMap;
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.toneMapping = THREE.ACESFilmicToneMapping;

      //环境光
      scene.add(new THREE.AmbientLight(0xe0ffff, 1));

      //用于计算位置
      let geometry = new THREE.CapsuleGeometry(0.01, 0.01, 4, 8);
      let material = new THREE.MeshBasicMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: 0,
        wireframe: true,
      });
      let capsulePerson = new THREE.Mesh(geometry, material);
      scene.add(capsulePerson);
      //nzp
      capsulePerson.position.set(1, 1.15, 0);
      capsulePerson.position.add(offsetVec);

      //碰撞检测(位置)
      // let startY = 0.6;
      // let endY = startY + 0.4;
      // let startX = offsetVec.x;
      // let endZ = -0.5;
      let startY = 0.75;
      let startX = 5.89;
      let endY = 1.15;
      let endZ = -16.49;
      let totalCnt = 11;
      let currentCnt = 0;

      //nzp
      let playerCollider = new Capsule(
        new THREE.Vector3(startX, startY, endZ),
        new THREE.Vector3(startX, endY, endZ),
        0.3
      );
      //console.log("playerCollider ",playerCollider);

      //鼠标事件
      document.addEventListener("keydown", (event) => {
        keyStates[event.code] = true;
        if (
          event.code == "KeyW" ||
          event.code == "KeyS" ||
          event.code == "KeyD" ||
          event.code == "KeyA"
        ) {
          if (walkFlag == false) {
            // fadeToAction('idle', 'walk', 0.5)
          }
          walkFlag = true;
        }
      });
      //鼠标事件
      document.addEventListener("keyup", (event) => {
        keyStates[event.code] = false;
        if (
          event.code == "KeyW" ||
          event.code == "KeyS" ||
          event.code == "KeyD" ||
          event.code == "KeyA"
        ) {
          if (walkFlag == true) {
            // fadeToAction('walk', 'idle', 0.5)
          }
          walkFlag = false;
        }
      });
      //鼠标事件
      document.addEventListener("mousedown", (event) => {
        if(firstUpdate) return;
        keyStates[event.button] = true;
      });
      //手指事件
      let mouse = new THREE.Vector2();
      let raycaster = new THREE.Raycaster();
      document.addEventListener("mouseup", (event) => {
        if(firstUpdate) return;
        if (keyStates[0] && !walkFlag) {
          if (event.isPrimary === false) return;
          mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

          raycaster.setFromCamera(mouse, camera);
          let intersects = raycaster.intersectObject(scene, true);
          if (intersects.length > 0) {
            for (let index = 0; index < intersects.length; index++) {
              let selectedObject = intersects[index].object;
              if (selectedObject.type == "npc") {
                alert("你好我是npc");
              }
              if (selectedObject.type == "npchead") {
                //弹框
                _this.show8 = 1;
                break;
              }
              let jumpInfo = selectedObject.name.split('_');
             
              if (jumpInfo[0] == "draw") {
                _this.show10 = 1;
                _this.src = picList[jumpInfo[1]-1].name  +'&token=' + _this.token;
                // window.location.href = picList[jumpInfo[1]-1].name +'&token=' + _this.token;
                break;
              }
            }
          }
        }
        keyStates[event.button] = false;
      });
      //手指事件
      document.addEventListener("touchstart", (event) => {
        if(firstUpdate) return;
        keyStates[0] = true;
      });
      //手指事件
      document.addEventListener("touchend", (event) => {
        if(firstUpdate) return;
        if (keyStates[0] && !walkFlag) {
          if (event.isPrimary === false) return;

          mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

          raycaster.setFromCamera(mouse, camera);
          let intersects = raycaster.intersectObject(scene, true);
          if (intersects.length > 0) {
            for (let index = 0; index < intersects.length; index++) {
              let selectedObject = intersects[index].object;
              if (selectedObject.type == "npc") {
                alert("你好我是npc");
              }
              if (selectedObject.type == "npchead") {
                //弹框
                _this.show8 = 1;
                break;
              }
              let jumpInfo = selectedObject.name.split('_');
              if (jumpInfo[0] == "draw") {
                if (jumpInfo[1] <= 34) {
                  _this.src = picList[jumpInfo[1]-1].name  +'&token=' + _this.token;
                  // window.location.href = picList[jumpInfo[1] - 1].name +'&token=' + _this.token;
                }
                break;
              }
            }
          }
        }
        keyStates[0] = false;
      });
      //尺寸监听
      window.addEventListener("resize", onWindowResize);

      function onWindowResize() {
        if (camera) {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
        }
        if (renderer) {
          renderer.setSize(window.innerWidth, window.innerHeight);
        }
      }

      //周边围栏
      addObstacle();

      function requestHttp() {
        axios.get("https://3dzl.zhongyigames.com/api/login/index")
          .then((response) => {
            //console.log("success response", response);
            if (response.data.code == 200) {
              picList = response.data.data;
              //name: 'https://www.baidu.com/', img: 'http://vrgame.zhongyigames.com/storage/goodsImg/20230504/d52267559a383ec4ff8f7b681e77a5e9.jpg', booth: 1
              _this.figure = response.data.data.figure
              initHua();
            }
          })
        
          .catch((response) => {
            console.log('fail response ', response);
          });
      }
      requestHttp();

      //碰撞
      function playerCollisions() {
        let result = worldOctree.capsuleIntersect(playerCollider);
        playerOnFloor = false;
        if (result && playerCollider) {
          playerOnFloor = result.normal.y > 0;
          if (!playerOnFloor) {
            playerVelocity.addScaledVector(
              result.normal,
              -result.normal.dot(playerVelocity)
            );
          }
          playerCollider.translate(result.normal.multiplyScalar(result.depth));
        }
      }

      //更新玩家位置
      let firstUpdate = true;

      let firstTime = 0;
      function updatePlayer(deltaTime) {
        let damping = Math.exp(-4 * deltaTime) - 1;
        if (!playerOnFloor) {
          playerVelocity.y -= GRAVITY * deltaTime;
          damping *= 0.1;
        }
        playerVelocity.addScaledVector(playerVelocity, damping);
        // console.log("playerVelocity ",playerVelocity);
        let deltaPosition = playerVelocity.clone().multiplyScalar(deltaTime);
        playerCollider.translate(deltaPosition);
        playerCollisions();
        let resPos = playerCollider.end;
        capsulePerson.position.copy(resPos);

        if (peopleObj != null) {
          peopleObj.position.copy(resPos.clone().setY(resPos.y - 0.7));
          //小地图位置
          //if (true) {
            let ratioX = ((resPos.x + 8.5) / 17).toFixed(3);
            let ratioY = ((resPos.z + 18.3) / 41.8).toFixed(3);

            _this.x = parseFloat(ratioX) * 60.14;
            _this.y = parseFloat(ratioY) * 192.5;
          //}

          let pos = peopleObj.position.clone();
          pos = pos.setY(pos.y + 0.7);
          // console.log("pos --> ",pos);
          // 调整相机
          if (oControls && oControls.target) {
            if (firstUpdate) {
              if (isCameraAction) {
                if (firstTime == 0) {
                  // current1 相机当前的位置 target1 相机的controls的target
                  // current2 新相机的目标位置 target2 新的controls的target
                  animateCamera(startCameraPos, startControlPos, endCameraPos, endControlPos)
                  firstTime++;
                }
              }
            } else {
              camera.position.sub(oControls.target); //减去向量
              oControls.target.copy(pos);
              camera.position.add(pos);
            }
          }
        }
      }
      //键盘事件
      function getForwardVector() {
        capsulePerson.getWorldDirection(playerDirection);
        playerDirection.y = 0;
        playerDirection.normalize();
        return playerDirection;
      }
      //键盘事件
      function getSideVector() {
        capsulePerson.getWorldDirection(playerDirection);
        playerDirection.y = 0;
        playerDirection.normalize();
        playerDirection.cross(capsulePerson.up);
        return playerDirection;
      }
      //摇杆方向
      function getRockVector(radian) {
        if (radian) {
          let tmpZ = Math.sin(radian);
          let tmpX = -Math.cos(radian);
          rockDirection.x = tmpX;
          rockDirection.y = 0;
          rockDirection.z = tmpZ;
          rockDirection.normalize();
        } else {
          rockDirection.x = 0;
          rockDirection.y = 0;
          rockDirection.z = 0;
        }
        return rockDirection;
      }
      //摇杆事件
      function moveRock(deltaTime) {
        if (isTouchRocker && tmpRockRadian > 0) {
          let rotate = -startOControl + tmpRockRadian;
          // console.log(rotate);
          if (rotate > Math.PI * 2) {
            rotate = rotate - Math.PI * 2;
          } else if (rotate < -(Math.PI * 2)) {
            rotate = rotate + Math.PI * 2;
          }

          peopleObj.rotateY(rotate);
          startOControl = JSON.parse(JSON.stringify(tmpRockRadian));
          let rockDir = getRockVector(
            tmpRockRadian + oControls.getAzimuthalAngle() - Math.PI
          );
          //movespeed
          let speedDelta = deltaTime * (playerOnFloor ? 15 : 4);
          playerVelocity.add(rockDir.multiplyScalar(speedDelta));
        }
      }
      //键盘(wasd)
      function controls(deltaTime) {
        capsulePerson.rotation.y = camera.rotation.y;
        // gives a bit of air control
        let speedDelta = deltaTime * (playerOnFloor ? 25 : 8);

        if (keyStates["KeyW"]) {
          playerVelocity.add(getForwardVector().multiplyScalar(-speedDelta));
        }
        if (keyStates["KeyS"]) {
          playerVelocity.add(getForwardVector().multiplyScalar(speedDelta));
        }
        if (keyStates["KeyA"]) {
          playerVelocity.add(getSideVector().multiplyScalar(speedDelta));
        }
        if (keyStates["KeyD"]) {
          playerVelocity.add(getSideVector().multiplyScalar(-speedDelta));
        }
        if (playerOnFloor) {
          if (keyStates["Space"]) {
            playerVelocity.y = 8;
          }
        }
      }
      //(add)周边围栏
      function addObstacle() {
        let isTransparent = true;
        let offsetX = -8;
        let offsetY = 0;
        let offsetZ = -5;
        //地板
        let geometry = new THREE.BoxGeometry(5, 1, 16);
        let material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        let cube = new THREE.Mesh(geometry, material);
        cube.material.transparent = isTransparent;
        cube.material.opacity = 0;
        cube.position.x = 9 + offsetX;
        cube.position.y = -0.05 + offsetY;
        cube.position.z = 0 + offsetZ;
        cube.rotateY(Math.PI / 2);
        scene.add(cube);
        worldOctree.fromGraphNode(cube);
        //长边(1)
        let geometry1 = new THREE.BoxGeometry(0.5, 1, 44);
        let material1 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        let cube1 = new THREE.Mesh(geometry1, material1);
        cube1.material.transparent = isTransparent;
        cube1.material.opacity = 0;
        cube1.position.x = 0.3 + offsetX;
        cube1.position.y = 1 + offsetY;
        cube1.position.z = 8 + offsetZ;
        scene.add(cube1);
        worldOctree.fromGraphNode(cube1);
        //长边(2)
        let geometry2 = new THREE.BoxGeometry(0.5, 1, 44);
        let material2 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        let cube2 = new THREE.Mesh(geometry2, material2);
        cube2.material.transparent = isTransparent;
        cube2.material.opacity = 0;
        cube2.position.x = 16.5 + offsetX;
        cube2.position.y = 1 + offsetY;
        cube2.position.z = 8 + offsetZ;
        scene.add(cube2);
        worldOctree.fromGraphNode(cube2);
        //短边(1)
        let geometry3 = new THREE.BoxGeometry(0.5, 1, 17);
        let material3 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        let cube3 = new THREE.Mesh(geometry3, material3);
        cube3.material.transparent = isTransparent;
        cube3.material.opacity = 0;
        cube3.position.x = 9 + offsetX;
        cube3.position.y = 1 + offsetY;
        cube3.position.z = -13 + offsetZ;
        cube3.rotateY(Math.PI / 2);
        scene.add(cube3);
        worldOctree.fromGraphNode(cube3);
        //短边(2)
        let geometry4 = new THREE.BoxGeometry(0.5, 1, 17);
        let material4 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        let cube4 = new THREE.Mesh(geometry4, material4);
        cube4.material.transparent = isTransparent;
        cube4.material.opacity = 0;
        cube4.position.x = 9 + offsetX;
        cube4.position.y = 1 + offsetY;
        cube4.position.z = 28.5 + offsetZ;
        cube4.rotateY(Math.PI / 2);
        scene.add(cube4);
        worldOctree.fromGraphNode(cube4);
      }
      //镜面
      async function initMirrorEffect(){
        let geometry = new THREE.PlaneGeometry(8, 10)
        groundMirror = new Reflector(geometry, {
          clipBias: 0.003,
          textureWidth: window.innerWidth * window.devicePixelRatio,
          textureHeight: window.innerHeight * window.devicePixelRatio,
          color: '#eeeeee'
        })
        groundMirror.position.y = 0.2;
        groundMirror.position.x = 0.5;
        groundMirror.position.z = 2.6;
        groundMirror.rotateX(- Math.PI / 2)
        scene.add(groundMirror)
      }
      //圆圈光效
      async function initCircleLight() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + "guanghuan.glb");
        } else {
          gltf = await loader.loadAsync('./models/guanghuan.glb');
        }
        let obj = gltf.scene;
        let animations = gltf.animations;
        circleLightMixer = new THREE.AnimationMixer(obj);
        let modelaction = animations[0];
        let ani = circleLightMixer.clipAction(modelaction);
        ani.play();
        scene.add(obj);
        gltf.scene.traverse(child => {
          if (child.isMesh) {
            //开启透明通道
            child.material.transparent = true;
          }
        });
        dracoLoader.dispose();
      }
      //魔方
      async function initBox() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + "mofang.glb");
        } else {
          gltf = await loader.loadAsync('./models/mofang.glb');
        }
        let obj = gltf.scene;

        // console.log("anima ", gltf.animations);
        let animations = gltf.animations;
        boxMixer = new THREE.AnimationMixer(obj);
        let modelaction = animations[0];
        let ani = boxMixer.clipAction(modelaction);
        ani.play();
        scene.add(obj);
        gltf.scene.traverse(child => {
          if (child.isMesh) {
            child.castShadow = true;
          }
        });
        worldOctree.fromGraphNode(obj);
        dracoLoader.dispose();
        addCnt("initBox");
      }
      //石头
      async function initStone() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'stone.glb');
        } else {
          gltf = await loader.loadAsync('./models/stone.glb');
        }
        let obj = gltf.scene;

        // console.log("anima ", gltf.animations);
        let animations = gltf.animations;
        stoneMixer = new THREE.AnimationMixer(obj);
        let modelaction = animations[0];
        let ani = stoneMixer.clipAction(modelaction);
        ani.play();
        scene.add(obj);
        dracoLoader.dispose();
        addCnt("initStone");
      }
      //两边展板
      async function initZhanTai() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'zhantai.glb');
        } else {
          gltf = await loader.loadAsync('./models/zhantai.glb');
        }
        let obj = gltf.scene;
        gltf.scene.traverse(child => {
          if (child.isMesh) {
            child.receiveShadow = true;
            child.material.emissiveIntensity = 0;
          }
        });
        worldOctree.fromGraphNode(obj);
        scene.add(obj);
        dracoLoader.dispose();
        addCnt("initZhanTai");
      }
      let ziMuMap2;
      //圆圈画
      async function initZiMu() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'zimu.glb');
        } else {
          gltf = await loader.loadAsync('./models/zimu.glb');
        }
        let obj = gltf.scene;
        let offsetVec1 = new THREE.Vector3(0, 1.5, 0);
        obj.position.add(offsetVec1);
        let copyObj = obj.clone();
        scene.add(obj);
        scene.add(copyObj);
        let offsetVec2 = new THREE.Vector3(0, -0.8, 15);
        copyObj.position.add(offsetVec2);

        gltf.scene.traverse(child => {
          if (child.name == "Caption_bar") {
            child.material.side = 2;
            ziMuMap2 = child.material.map;
            if (ziMuMap2) {
              ziMuMap2.wrapS = THREE.RepeatWrapping;
              ziMuMap2.wrapT = THREE.RepeatWrapping;
              ziMuMap2.repeat.set(1, 1);
            }
          }
        });
        dracoLoader.dispose();
      }
      let yuanHuaMap;
      //圆圈画
      async function initYuanHua() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'yuanhuan.glb');
        } else {
          gltf = await loader.loadAsync('./models/yuanhuan.glb');
        }
        let obj = gltf.scene;
        scene.add(obj);
        gltf.scene.traverse(child => {
          if (child.isMesh) {
            child.castShadow = true;
          }
          //yuanlun02  yuanlun03 Circle041
          if (child.name == "Circle041_1") {
                yuanHuaMap = child.material.emissiveMap;
                if (yuanHuaMap) {
                  yuanHuaMap.wrapS = THREE.RepeatWrapping;
                  yuanHuaMap.wrapT = THREE.RepeatWrapping;
                  yuanHuaMap.repeat.set(1, 1);
                }
          }
        });
        dracoLoader.dispose();
        addCnt("initYuanHua");
      }
      //展画
      async function initHua() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'hua.glb');
        } else {
          gltf = await loader.loadAsync('./models/hua.glb');
        }
        let obj = gltf.scene;
        gltf.scene.traverse(child => {
          if (child.isMesh) {
            let id = Number(child.name.split('_')[1]);
            if (picList && picList[ id - 1]) {
              let picUrl = picList[id - 1].img;
              if (picUrl) {
                let textureLoader = new THREE.TextureLoader(); //纹理贴图加载器
                let tex = textureLoader.load(picUrl)
                tex.flipY = false
                tex.encoding = THREE.sRGBEncoding;
                child.material.map = tex;
                child.material.emissiveMap = tex;
              }
            }
          }
        });
        scene.add(obj);
        worldOctree.fromGraphNode(obj);
        dracoLoader.dispose();
      }
      //展画阴影
      async function initShadow() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'yinying.glb');
        } else {
          gltf = await loader.loadAsync('./models/yinying.glb');
        }
        let obj = gltf.scene;
        scene.add(obj);
        dracoLoader.dispose();
        addCnt("initShadow1");
      }
      //灯光贴图
      async function initLight() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        //地板灯光
        let gltf;
        //地板
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'light_g.glb');
        } else {
          gltf = await loader.loadAsync('./models/light_g.glb');
        }
        let obj = gltf.scene;
        obj.name = "light_g"
        scene.add(obj);
        // console.log("11111 ",obj);
        worldOctree.fromGraphNode(obj);
        obj.position.set(0, 0.1, 0);
        dracoLoader.dispose();
        addCnt("initLight");
      }
      //
      //顶部
      async function initHuaBox() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);

        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'pengzhuang.glb');
        } else {
          gltf = await loader.loadAsync('./models/pengzhuang.glb');
        }
        let obj = gltf.scene;
        worldOctree.fromGraphNode(obj);
        scene.add(obj);
        dracoLoader.dispose();
      }
      let dingMap;
      //顶部
      async function initDing() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);

        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'ding.glb');
        } else {
          gltf = await loader.loadAsync('./models/ding.glb');
        }
        let obj = gltf.scene;
        //nzp
        obj.remove(obj.getObjectByName("AmbientLight"));
        let mat = obj.children[0].children[0].children[0].material;
        dingMap = mat.emissiveMap;
        if (dingMap) {
          dingMap.wrapS = THREE.RepeatWrapping;
          dingMap.wrapT = THREE.RepeatWrapping;
          dingMap.repeat.set(1, 1);
        }
        // obj.children[0].visible = false;
        scene.add(obj);
        dracoLoader.dispose();
        addCnt("initDing");
      }
      //天空盒
      async function initSkybox() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'tiankonghe.glb');
        } else {
          gltf = await loader.loadAsync('./models/tiankonghe.glb');
        }

        let obj = gltf.scene;
        scene.add(obj);
        dracoLoader.dispose();
        addCnt("initSkybox");
      }
      //人物
      async function initPeople() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'girl_all.glb');
        } else {
          gltf = await loader.loadAsync("models/girl_all.glb");
        }

        peopleObj = gltf.scene;
        peopleObj.scale.set(1, 1, 1);
        peopleObj.position.set(1, 0.5, 0);
        peopleObj.position.add(offsetVec);

        // 组合对象添加到场景中
        scene.add(peopleObj);
        //动画
        let animations = gltf.animations;
        mixer = new THREE.AnimationMixer(peopleObj);

        let modelaction = animations[0];

        let actionIdle = mixer.clipAction(THREE.AnimationUtils.subclip(modelaction, 'idle', 1, 48));
        let actionWalk = mixer.clipAction(THREE.AnimationUtils.subclip(modelaction, 'walk', 51, 78));

        baseActions["idle"].action = actionIdle;
        baseActions["walk"].action = actionWalk;

        gltf.scene.traverse(child => {
          if (child.isMesh) {
            child.castShadow = true;
          }
        });

        isLoadSceneFinish = true;
        dracoLoader.dispose();
        addCnt("initPeople 2");
      }
      function initVideo() {
        let tex;
        if (picList[34] && picList[34].img) {
          video.src = picList[34].img;
          tex = new THREE.VideoTexture(video);
          tex.minFilter = THREE.LinearFilter;
          tex.magFilter = THREE.LinearFilter;
          tex.wrapS = tex.wrapT = THREE.ClampToEdgeWrapping;

          let geometry = new THREE.PlaneGeometry(2, 1);
          let material = new THREE.MeshBasicMaterial({
            map: tex,
            side: THREE.BackSide
          });
          tex.encoding = THREE.sRGBEncoding;
          let plane = new THREE.Mesh(geometry, material);
          plane.position.set(0.8, 2, 8);
          video.play();
          scene.add(plane);
        }
      }
      //npc
      async function initNpc() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/gltf/");
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);

        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'boy_all.glb');
        } else {
          gltf = await loader.loadAsync('./models/boy_all.glb');
        }
        scene.add(gltf.scene);
        gltf.scene.traverse(child => {
          child.type = 'npc';
          if (child.isMesh) {
            let texture = new THREE.TextureLoader().load("./logo.png");
            let spriteMaterial = new THREE.SpriteMaterial({
              map: texture, //设置精灵纹理贴图
            });
            let sprite = new THREE.Sprite(spriteMaterial);
            sprite.scale.set(0.5, 0.5, 0.5);
            sprite.position.set(child.position.x, child.position.y + 2, child.position.z);
            sprite.type = "npchead";
            gltf.scene.add(sprite);
          }
        });
        gltf.scene.position.set(2, 0.4, -5);

        let animations = gltf.animations;
        npcMixer = new THREE.AnimationMixer(gltf.scene);
        let modelaction = animations[0];
        let ani = npcMixer.clipAction(modelaction);
        ani.play();

        dracoLoader.dispose();
        worldOctree.fromGraphNode(gltf.scene);
        addCnt("initNpc");
      }
      //地板
      async function initBase() {
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/gltf/');
        let loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        let gltf;
        if (isRemote) {
          gltf = await loader.loadAsync(remoteModlePath + 'diban.glb');
        } else {
          gltf = await loader.loadAsync('./models/diban.glb');
        }
        let obj = gltf.scene;
        obj.name = "diban"
        scene.add(obj);
        gltf.scene.traverse(child => {
          if (child.isMesh) {
            // child.receiveShadow = true;
          }
        });
        worldOctree.fromGraphNode(obj);
        dracoLoader.dispose();
        addCnt("initBase");
      }


      function addCnt(strName) {
        console.log(strName);
        currentCnt++;
        let pro = Math.floor(currentCnt * 100 / totalCnt);
        _this.lodingPer = pro
        if (currentCnt == totalCnt) {
          _this.lodingPer = 100
          _this.load = 0;
          _this.show1 = 1;
        }
      }

      //轮询渲染
      function animateRender() {
        let tmpTime = clock.getDelta();
        let deltaTime = Math.min(0.05, tmpTime) / STEPS_PER_FRAME;

        if (dingMap) {
          dingMap.offset.y += 0.003;
        }
        if(yuanHuaMap){
          yuanHuaMap.offset.y += 0.0005;
        }
        if (ziMuMap2) {
          ziMuMap2.offset.x += 0.0008;
        }
        //nzp 初始位置
        // console.log("camera  --> ",camera);
        // console.log("oControls  --> ",oControls);

        let delta = Math.min(tmpTime, 0.1);
        //人物动作
        if (mixer) {
          mixer.update(delta);
        }
        if(stoneMixer){
          stoneMixer.update(delta);
        }
        if(boxMixer){
          boxMixer.update(delta);
        }
        if(npcMixer){
          npcMixer.update(delta);
        }
        if(circleLightMixer){
          circleLightMixer.update(delta);
        }
        if (oControls && oControls.target && peopleObj) {
          let po = camera.position.clone();
          po.sub(oControls.target)
            .normalize()
            .multiplyScalar(-1)
            .add(oControls.target);

          peopleObj.rotateY(
            oControls.getAzimuthalAngle() - lastTmpRockRadian
          );
          lastTmpRockRadian = JSON.parse(
            JSON.stringify(oControls.getAzimuthalAngle())
          );
        }

        if (oControls) {
          oControls.update();
        }
        for (let i = 0; i < STEPS_PER_FRAME; i++) {
          controls(deltaTime);
          moveRock(deltaTime);
          if (isLoadSceneFinish) {
            updatePlayer(deltaTime);
          }
        }
        if(renderer){
          renderer.render(scene, camera);
        }
        if(tween){
          TWEEN.update();
        }
        // if (stats) {
        //   stats.update();
        // }
        requestAnimationFrame(animateRender);
      }
      //不知道是啥,执行顺序靠前
      // console.log("value ",canvasDom.value);
      canvasDom.appendChild(renderer.domElement);

      // stats = new Stats();
      // canvasDom.appendChild(stats.dom);

      oControls = new OrbitControls(camera, renderer.domElement);
      oControls.maxPolarAngle = Math.PI * 0.5;
      oControls.enableZoom = true;
      oControls.maxDistance = 20;
      oControls.target.set(startControlPos.x, startControlPos.y, startControlPos.z);
   

      var tween;
      // current1 相机当前的位置
      // target1 相机的controls的target
      // current2 新相机的目标位置
      // target2 新的controls的target
      function animateCamera(current1, target1, current2, target2) {
        let positionVar = {
          x1: current1.x,
          y1: current1.y,
          z1: current1.z,
          x2: target1.x,
          y2: target1.y,
          z2: target1.z
        };
        //关闭控制器
        oControls.enabled = false;
        tween = new TWEEN.Tween(positionVar);
        tween.to({
          x1: current2.x,
          y1: current2.y,
          z1: current2.z,
          x2: target2.x,
          y2: target2.y,
          z2: target2.z
        }, 2500);

        tween.onUpdate(function () {
          camera.position.x = positionVar.x1;
          camera.position.y = positionVar.y1;
          camera.position.z = positionVar.z1;
          oControls.target.x = positionVar.x2;
          oControls.target.y = positionVar.y2;
          oControls.target.z = positionVar.z2;
          oControls.update();
        })

        tween.onComplete(function () {
          ///开启控制器
          // console.log("结束了！！");
          firstUpdate = false;
          _this.createJoysticks();
          oControls.enabled = true;
        })

        tween.easing(TWEEN.Easing.Cubic.InOut);
        tween.start();
      }

      initBox(); 
      initStone();
      initZhanTai();
      initYuanHua();
      
      initLight(); 
      initBase(); 
      initPeople();
      initSkybox(); 
      initDing(); 
      initShadow();
      initNpc(); 
      //不
      initHuaBox();
      initZiMu();
      initCircleLight();
      initMirrorEffect();

      animateRender();
    },
    ///////////////////////////////摇杆逻辑///////////////////////////////
    createJoysticks() {
      var s = function (sel) {
        return document.querySelector(sel);
      };
      joysticks = {
        static: {
          zone: s(".zone.static"),
          mode: "static",
          // position: {left: '10%', bottom: '10%'},
          position: { left: "100px", bottom: "100px" },
          color: "#333333",
        },
      };
      this.createNipple("static");
    },

    bindNipple() {
      var _this = this;
      joystick
        .on("start", function (evt, data) {
          _this.debug(data, true);
        })
        .on("end", function (evt, data) {
          _this.debug(data, false);
        })
        .on("move", function (evt, data) {
          _this.debug(data, true);
        })
        .on(
          "dir:up plain:up dir:left plain:left dir:down " +
          "plain:down dir:right plain:right  ",
          function (evt, data) { }
        )
        .on("pressure", function (evt, data) { });
    },
    createNipple(evt) {
      var type =
        typeof evt === "string" ? evt : evt.target.getAttribute("data-type");
      if (joystick) {
        joystick.destroy();
      }
      joystick = nipplejs.create(joysticks[type]);
      this.bindNipple();
    },

    debug(obj) {
      var _this = this;
      if (obj) {
        if (obj.angle) {
          tmpRockRadian = obj.angle.radian;
          let preTouch = isTouchRocker;
          isTouchRocker = true;
          if (preTouch == false && isTouchRocker == true) {
            _this.fadeToAction("idle", "walk", 0.5);
          }
        } else {
          isTouchRocker = false;
          tmpRockRadian = null;
          walkFlag = false;
          playerVelocity.x = 0;
          playerVelocity.z = 0;
          _this.fadeToAction("walk", "idle", 0.5);
          // console.log("停止摇杆");
        }
      } else {
        tmpRockRadian = null;
      }
    },

    //动作
    fadeToAction(first, last, duration) {
      let previousAction = baseActions[first].action;
      let activeAction = baseActions[last].action;

      if (previousAction !== activeAction) {
        previousAction.fadeOut(duration);
      }
      activeAction
        .reset()
        .setEffectiveTimeScale(1)
        .setEffectiveWeight(1)
        .play();
    }
  },
};
</script>
 
<style>
body {
  overflow: hidden;
}

.front {
  background-image: url("./assets/image/small.png") !important;
  background-size: cover !important;
}

.back {
  background-image: url("./assets/image/big.png") !important;
  background-size: cover !important;
}

#Loading {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;
}

@-webkit-keyframes ball-beat {
  50% {
    opacity: 0.2;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ball-beat {
  50% {
    opacity: 0.2;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ball-beat>div {
  background-color: #279fcf;
  width: 15px;
  height: 15px;
  border-radius: 100% !important;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: ball-beat 0.7s 0s infinite linear;
  animation: ball-beat 0.7s 0s infinite linear;
}

.ball-beat>div:nth-child(2n-1) {
  -webkit-animation-delay: 0.35s !important;
  animation-delay: 0.35s !important;
}

.go {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 3);
  overflow: hidden;
  z-index: 999;
  color: #fff;
}

.navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  z-index: 999;
  color: #fff;
}

.navigation3 img {
  width: 35px;
  height: 35px;
}

.navigation3_1 {
  display: flex;
  justify-content: flex-end;
}

.navigation3_1 img {
  width: 35px;
  height: 35px;
}

.navigation4 {
  display: flex;
  margin-left: 22px;
}

.navigation4 img {
  width: 11px;
  height: 14px;
}

.navigation5 {
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
}



.go_ont {
  position: fixed;
  /*left: 25%;
  top: 30%;*/
  /* left: 14rem;
  top: 6rem;
  margin-left: -50px;
  margin-top: -50px; */
  font-size: 16px;
  color: #FFFFFF;
  width: 45%;
}

.go_ont_i img {
  width:100%;
  
}


.go_ont_t {
  position: absolute;
  top: 0px;
  width: 100%;
}

.go_ont_t_i {
  display: flex;
  justify-content: flex-end;
}

.go_ont_t_i img {
  width: 22px;
  height: 22px;
  margin-top: 20px;
  margin-right: 20px;
}

.go_ont_tt {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 15%;
}

.go_ont_q_i {
  display: flex;
  justify-content: center;

}
/* 
.go_ont_q_i img {
  width: 50%;
  margin-top: 7%;
} */

.different {
  display: flex;
  justify-content: center;
  position: fixed;
  /*top: 20%;*/
  top: 3rem;
  width: 100%;
}

.different img {
  /*width: 80%;*/
  height: 15rem;
}

.go_ont_q {
  /* position: absolute;
  bottom: 10px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 16rpx; */
  width: 50%;
  margin-top: 7%;
  padding-top: 3%;
  padding-bottom: 3%;
  background: url('@/assets/image/button2.png') no-repeat;
  /* background-size: cover; */
  background-size:100% 100%;
  /* background-attachment:fixed; */
  text-align: center;

}


.go_ont_q_i1 {
  display: flex;
  justify-content: center;
}

.go_ont_q_i1 img {
  width: 200px;
  height: 52px;
  /* margin-top: 500px; */
  z-index: 99;
}

.go_ont_q1 {
  position: absolute;
  top: 15px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 16rpx;
  color: #fff;
  z-index: 99;
}
.go_div{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.go_div_o {
  position: fixed;
  top: 65%;
  width: 100%;
}

.navigation1 {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}

.nextstep {
  position: fixed;
  bottom: 60px;
  width: 100%;
}

.nextstep_o {
  width: 160px;
  height: 40px;
  background: #525254;
  border-radius: 20px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.slide {
  position: fixed;
  left: 5%;
  bottom: 5%;
}

.slide img {
  width: 84.98px;
  height: 107.14px;
}


.rotary {
  position: fixed;
  right: 5%;
  bottom: 5%;
}

.rotary img {
  width: 167.18px;
  height: 133.93px;
}


.slide1 {
  width: 169px;
  height: 32px;
  background: rgba(82, 82, 84, 0.6);
  border-radius: 8px;
  font-size: 13rpx;
  font-weight: 500;

  line-height: 32px;
  text-align: center;
}

.slide1_1 {
  color: #3482FF;
}

.slide2 {
  margin-top: 20px;
}

.menu {
  display: flex;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
}

.text {
  height: 14rem;
  background: rgba(0, 0, 0, 0.3);
  width: 25rem;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.menu_i {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.turn{
      /* border: 1px solid red; */
      width:100px;
      height: 100px;
      animation:turn 1.5s linear infinite;      
      margin: 100px auto;
      background: url("@/assets/image/loading.png") no-repeat;
      background-size: 100% 100%;
    }
    /* 
      turn : 定义的动画名称
      1s : 动画时间
      linear : 动画以何种运行轨迹完成一个周期
      infinite :规定动画应该无限次播放
     */
    @keyframes turn{
      0%{-webkit-transform:rotate(0deg);}
      25%{-webkit-transform:rotate(90deg);}
      50%{-webkit-transform:rotate(180deg);}
      75%{-webkit-transform:rotate(270deg);}
      100%{-webkit-transform:rotate(360deg);}
    }

  .loadText{
    width: 100px;
    position: absolute;
    top: 46%;
    text-align: center;
  }

  .map{
    margin-top: 4rem;
    background: rgba(0,0,0,0);
    position: fixed;
    top: 20px;
    left: 40px;
  }

  .map_i{
    width: 100%;
    position: relative;
  }

  .map_i img{
    width: 200px;
  }

  .map_d{
    position: absolute;
  }

  .map_d img{
    width: 15px;
  }
</style>
 