import { createApp } from 'vue'
import App from './App.vue'
// import Vconsole from 'vconsole'


import '@/assets/css/main.css'

// let vConsole = new Vconsole();
// export default vConsole
createApp(App).mount('#app')
